import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate, Switch, Redirect } from "react-router-dom";
import SignIn from "./SignInUp/SignIn";
import SignUp from "./SignInUp/SignUp";
import LandingPage from "./Landing/LandingPage";
// import Ensemble from "./Moduls/Ensemble/PageEnsemble";
import ProfilePage from "./User/ProfilePage";
import PasswordReset from "./SignInUp/PasswordReset";
// import TestPage from "./User/Test";
// import Chords from "./Moduls/Chords/PageChords";

const Ensemble = lazy(() => import("./Moduls/Ensemble/PageEnsemble"));
const Chords = lazy(() => import("./Moduls/Chords/PageChords"));

function Application() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/reset" element={<PasswordReset />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/ensemble" element={<Ensemble />} />
        <Route exact path="/chords" element={<Chords />} />
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </Suspense>
  );
}
export default Application;
