import {
  getAuth,
  sendEmailVerification,
  connectAuthEmulator,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  collection,
  onSnapshot,
  query,
  where,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { loadStripe } from "@stripe/stripe-js";

const { initializeApp } = require("firebase/app");
const {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} = require("firebase/app-check");

//FIREBASE Config - Projekteinstellungen
var firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_FIREBASE_APP_ID,
};

const firebase = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaEnterpriseProvider(
    "6Ld7rCAdAAAAAPBejDb1_bhYd59GiYhN76nuYxlK" //prod
    // "6LfiQR8dAAAAAGgiDjbquvs2-nHTc9Mh-uRwO_aE" //dev
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export default firebase;

const STRIPE_PUBLISHABLE_KEY =
  // "pk_test_51JhcC3FC91m7vqOMNl1JCEqatiaWD6LudP0jfm0oKdv8wHTQqbVHNcZe4eJGNZuOhZ94IIawUeg8tgZKCpG142sX00eCHqdZCn"; // dev
  "pk_live_51Jeyk0DDjydJjf3Wl33waJXH7OwZaflmsfNc522v53EY2RLgMt2VFdc8cJRKL9TE6oDg0H5gqfXdKSvmGQtdwrVE00VQJrAR7Q"; // prod
// const taxRates = ["txr_1JhcrBFC91m7vqOMVkcRZfAs"]; // dev
const taxRates = ["txr_1Jsb8uDDjydJjf3WW8JOgHGS"]; // prod
// const price = "price_1JhdJ4FC91m7vqOMPrAP6hSF"; // dev
const price = "price_1JrqrKDDjydJjf3WzRwliJtT"; // prod

export const auth = getAuth();
export const firestore = getFirestore();
export const functions = getFunctions(firebase, "europe-west3");
export const storage = getStorage();
if (window.location.hostname == "localhost") { // pretty cool but sometimes is emulator export buggy
  connectFunctionsEmulator(functions, "localhost", 5001); // nur in DEV!!
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

export const resendEmail = () => {
  sendEmailVerification(auth.currentUser).then(function () {
    // Email Verification sent!
    alert("Email Verification Sent!");
  });
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  // const docRef = doc(firestore, "users", user.uid);
  // const docSnap = await getDoc(docRef);
  try {
    const { email, displayName, photoURL } = user;
    await setDoc(doc(firestore, "users", user.uid), {
      displayName,
      email,
      photoURL,
      ...additionalData,
    });
  } catch (error) {
    console.error("#Firebase.js: Error creating user document", error);
    return error;
  }
  return getUserDocument(user);
};

export const getUserDocument = async (uid) => {
  if (!uid) return null;

  return getDoc(doc(firestore, "users", uid.uid))
    .then((response) => {
      return {
        uid,
        ...response.data(),
      };
    })
    .catch((error) => {
      console.error("#Firebase.js: Error fetching user", error);
      return error;
    });
};

export const getProductList = async (uid) => {
  if (!uid) return null;
  let prices = {};
  let products = {};
  let productId, productData, priceId, priceData;
  try {
    // const response = await firestore
    await firestore
      .collection("products")
      .where("active", "==", true)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(async function (doc) {
          const priceSnap = await doc.ref
            .collection("prices")
            .where("active", "==", true)
            .orderBy("unit_amount")
            .get();
          productId = doc.id;
          productData = doc.data();
          products[productId] = productData;
          priceSnap.docs.forEach(async (doc) => {
            priceId = doc.id;
            priceData = doc.data();
            prices[priceId] = priceData;
          });
        });
        return { products, prices };
      });
  } catch (error) {
    console.error("Error fetching product list", error);
  }
};

// // Checkout handler
export async function subscribeStripe(uid) {
  if (!uid) return null;
  const selectedPrice = {
    price: price,
  };
  selectedPrice.quantity = 1;
  const checkoutSession = {
    collect_shipping_address: true,
    tax_rates: taxRates,
    allow_promotion_codes: true,
    line_items: [selectedPrice],
    // success_url: window.location.origin,
    // cancel_url: window.location.origin,
    success_url: "https://app.intonica.de/profile",
    cancel_url: "https://app.intonica.de/profile",
    metadata: {
      key: "value",
    },
  };

  const docRef = await addDoc(
    collection(firestore, "customers", uid.uid, "checkout_sessions"),
    checkoutSession
  );
  onSnapshot(
    doc(firestore, "customers", uid.uid, "checkout_sessions", docRef.id),
    async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    }
  );
}

// Call billing portal function
export const getBillingPortalData = async () => {
  const functionRef = httpsCallable(
    functions,
    "ext-firestore-stripe-subscriptions-createPortalLink"
  );
  const { data } = await functionRef({
    returnUrl: "https://app.intonica.de/profile",
  });
  return data;
};

// Get custom claim role helper
async function getCustomClaimRole() {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
}

// Get all subscriptions for the customer
export const getCustomerSubsciptions = async (uid) => {
  let subscriptions = [];
  const q = query(
    collection(firestore, "customers/" + uid.uid + "/subscriptions"),
    where("status", "in", ["trialing", "active"])
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    subscriptions.push({ id: doc.id, data: doc.data() });
  });
  const role = await getCustomClaimRole();
  // console.log(subscriptions[0], subscriptions.length, role);
  return { role };
};
