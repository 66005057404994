import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const Footer = ({ transparent }) => {
  const [colors, setColors] = useState({
    tcolor: "var(--brightColor)",
    bcolor: "var(--themeColor)",
  });

  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });

  useEffect(() => {
    if (transparent === true) {
      setColors({ tcolor: "#707070", bcolor: "" });
    }
  }, [transparent]);

  return (
    <footer
      className="fixed-bottom"
      style={{
        maxHeight: "45px",
        minHeight: "45px",
        backgroundColor: colors.bcolor,
        opacity: "75%",
      }}
    >
      <nav
        className="navbar container footerNav"
        style={{
          backgroundColor: colors.bcolor,
        }}
      >
        <ul className="navbar-nav flex-row ms-md-auto"></ul>
        <a className="navbar-brand" href="/">
          {transparent ? (
            <img src="assets/logo/logo_small_grey.svg" alt="" height="35px" />
          ) : (
            <img src="assets/logo/logo_small_white.svg" alt="" height="35px" />
          )}
        </a>

        <ul className="navbar-nav flex-row align-items-center">
          {isMobile ? null : (
            <li className="nav-item col-md-auto">
              <a
                className="nav-link p-2"
                href="/"
                style={{ color: colors.tcolor }}
              >
                © {new Date().getFullYear()} Intonica
              </a>
            </li>
          )}
          <li className="nav-item col-md-auto">
            <a
              className="nav-link p-2"
              href="https://www.intonica.de/impressum"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.tcolor }}
            >
              Imprint
            </a>
          </li>
          <li className="nav-item col-md-auto">
            <a
              className="nav-link p-2"
              href="https://www.intonica.de/agb"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.tcolor }}
            >
              Terms
            </a>
          </li>
          <li className="nav-item col-md-auto">
            <a
              className="nav-link p-2"
              href="https://www.intonica.de/datenschutz"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.tcolor }}
            >
              Privacy
            </a>
          </li>
          <li className="nav-item col-md-auto">
            <a
              className="nav-link p-2"
              href="https://www.intonica.de/lizenzen"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.tcolor }}
            >
              Open Source
            </a>
          </li>
          <li className="nav-item col-md-auto">
            <a
              className="nav-link p-2"
              href="https://www.intonica.de/faq"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.tcolor }}
            >
              FAQ
            </a>
          </li>
        </ul>

        {isMobile ? null : (
          <ul className="navbar-nav flex-row ms-md-auto">
            <li className="nav-item col-md-auto">
              <a
                className="nav-link p-2"
                href="https://www.facebook.com/intonicamusic"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.tcolor }}
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li className="nav-item col-md-auto">
              <a
                className="nav-link p-2"
                href="https://www.instagram.com/intonicamusic/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.tcolor }}
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        )}
      </nav>
    </footer>
  );
};

export default Footer;
