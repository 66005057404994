const ComponentCard = ({ cardTitle, cardText, cardLink, cardImgUrl }) => {
  return (
    <div className="card" style={{ width: "18rem", borderRadius: "2rem" }}>
      <img
        src={cardImgUrl}
        className="card-img-top"
        alt=""
        style={{
          borderTopLeftRadius: "2rem",
          borderTopRightRadius: "2rem",
        }}
      />
      <div className="card-body">
        <h5 className="card-title" style={{fontWeight: 'bold'}}>{cardTitle}</h5>
        <p className="card-text" style={{textAlign: 'left'}}>{cardText}</p>
        <a href={cardLink} className="stretched-link" />
      </div>
    </div>
  );
};

export default ComponentCard;
