import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, functions, generateUserDocument } from "../../services/Firebase";

import { UserContext } from "../../providers/UserProvider";
import Footer from "../Footer/Footer";
import "./notch.css";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const user = useContext(UserContext);

  async function disposableEmail(email) {
    let url = "https://open.kickbox.com/v1/disposable/" + email;
    try {
      const response = await axios.get(url);
      // console.log("disposable: ", response);
      return response.data.disposable;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password,
    password2,
    displayName
  ) => {
    event.preventDefault();
    if (displayName === null || displayName === "") {
      setError("Erzähl uns bitte, wie wir Dich nennen dürfen.");
    } else {
      if (password === password2) {
        const verifyEmail = await disposableEmail(email);
        //TODO loading screen for user/server response
        if (!verifyEmail) {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              generateUserDocument(user, { displayName });
              sendEmailVerification(user);
              navigate("/profile");

              setEmail("");
              setPassword("");
              setPassword2("");
              setDisplayName("");
            })
            .catch((error) => {
              console.log(error.code);
              if (error.code === "auth/email-already-in-use") {
                // setError("Email already in use.");
                setError(
                  <a href="/signin">
                    Die E-Mail ist schon vergeben. Möchtest Du dich anmelden?
                  </a>
                );
              } else setError("Fehler beim Registrieren...");
              console.log(error.code);
              setPassword("");
              setPassword2("");
            });
        } else {
          console.log("Bitte benutze eine andere E-Mail.");
          setPassword("");
          setPassword2("");
          setError("Bitte benutze eine andere E-Mail.");
        }
      } else {
        setPassword("");
        setPassword2("");
        setError("Die Passwörter unterscheiden sich.");
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "userPassword2") {
      setPassword2(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }
  };

  return (
    <div
      className="landing h-100"
      style={{
        backgroundImage: "url(/assets/background_nav.jpg)",
      }}
    >
      <nav className="navbar container-xl flex-wrap flex-md-nowrap fixed-top text-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/" aria-label="Bootstrap">
            <img src="assets/logo/logo_big_text.svg" height="70px" alt="" />
          </a>
        </div>
      </nav>
      <div className="container-fluid" style={{ minHeight: "15vh" }}>
        <div
          className="row align-items-center text-center"
          style={{ minHeight: "15vh" }}
        >
          <header className="text-white ">
            <h2 style={{ textShadow: "1px 1px #000" }}>
              Willkommen bei
              <br />
              Intonica
            </h2>
          </header>
        </div>
      </div>

      <div
        className="container-fluid text-center align-items-center notch"
        style={{
          backgroundColor: "var(--brightColor)",
          minHeight: "85vh",
          padding: "5vh",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <h1>Registrieren</h1>
            <h6>
              Sie haben bereits ein Konto? <a href="/signin">Anmeldung</a>
            </h6>
            {error !== null && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="displayName"
                  id="displayName"
                  value={displayName}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="Name"
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="userEmail"
                  id="userEmail"
                  value={email}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="E-Mail-Adresse"
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="userPassword"
                  id="userPassword"
                  value={password}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="Passwort"
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="userPassword2"
                  id="userPassword2"
                  value={password2}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="Passwort wiederholen"
                />
              </div>
              <button
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  createUserWithEmailAndPasswordHandler(
                    event,
                    email,
                    password,
                    password2,
                    displayName
                  );
                }}
                className="btn btn-primary"
              >
                Registrieren
              </button>
            </form>
          </div>
          <div>
            <hr />
          </div>
          <p>
            <small>
              * Mit Ihrer Registrierung stimmen Sie unseren{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.intonica.de/agb"
              >
                Nutzungsbedingungen
              </a>{" "}
              sowie dem Erhalt von E-Mails und Updates von Intonica zu und
              bestätigen, dass Sie unsere{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.intonica.de/datenschutz"
              >
                Datenschutzrichtlinie
              </a>{" "}
              zur Kenntnis genommen haben.
            </small>
          </p>
          <p>
            <small>
              Sie nehmen außerdem zur Kenntnis, dass Intonica für eine
              bestmögliche Nutzererfahrung Cookies verwendet. Diese Website wird
              durch reCAPTCHA geschützt und es gelten die{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy"
              >
                Datenschutzerklärung
              </a>{" "}
              und{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/terms"
              >
                Nutzungsbedingungen
              </a>{" "}
              von Google.
            </small>
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SignUp;
