import React, { createContext, useEffect, useState } from "react";
import {
  auth,
  getCustomerSubsciptions,
  getUserDocument,
} from "../services/Firebase";

import { onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext({ user: null, stripe: null });

const UserProvider = ({ children }) => {
  const [state, setState] = useState({ user: null, stripe: null });
  useEffect(() => {
    onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        getUserDocument(userAuth).then((user) => {
          try {
            async function getCustomerSubsciptionsData() {
              const response = await getCustomerSubsciptions(user.uid);
              return response;
            }
            getCustomerSubsciptionsData().then((stripe) => {
              if (stripe.role === "premium") setState({ user, stripe });
              else setState({ user, stripe: { role: "basic" } });
            });
          } catch (err) {
            console.log(err);
            setState({ user, stripe: { role: "basic" } });
          }
        });
      }
    });
  }, []);
  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};
export default UserProvider;
