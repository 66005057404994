import React, { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
// import { auth } from "../../services/Firebase";
import { useMediaQuery } from "react-responsive";

import ConfirmMail from "../SignInUp/ConfirmMail";
import Dashboard from "./Dashboard";
import MobileScreen from "./MobileScreen";

import "./heart.css";
import NoUser from "./NoUser";

const ProfilePage = () => {
  const dataUserContext = useContext(UserContext);
  // const user = auth.currentUser;
  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });

  console.log(dataUserContext);

  const navigate = useNavigate();
  return (
    <div>
      {dataUserContext.user ? (
        dataUserContext.user.uid.emailVerified ? (
          isMobile ? (
            <MobileScreen
              modul={null}
              navigate={navigate}
              photoURL={dataUserContext.user.photoURL}
              setGoToPlayer={null}
              transparent={true}
            />
          ) : (
            <Dashboard dataUserContext={dataUserContext} navigate={navigate} />
          )
        ) : (
          <ConfirmMail email={dataUserContext.user.uid.email} />
        )
      ) : (
        <NoUser />
      )}
    </div>
  );
};

export default ProfilePage;
