import React, { useState } from "react";
import { auth } from "../../services/Firebase";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { sendPasswordResetEmail } from "firebase/auth";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {
          setEmailHasBeenSent(false);
        }, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };
  return (
    <div
      className="landing h-100"
      style={{
        backgroundImage: "url(/assets/background_nav.jpg)",
        // backgroundSize: "cover",
      }}
    >
      <nav className="navbar container-xl flex-wrap flex-md-nowrap fixed-top text-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/" aria-label="Bootstrap">
            <img src="assets/logo/logo_big_text.svg" height="100px" alt="" />
          </a>
        </div>
      </nav>
      <div className="container-fluid" style={{ minHeight: "20vh" }}>
        <div
          className="row align-items-center text-center"
          style={{ minHeight: "20vh" }}
        >
          <header
            className="text-white "
            style={{ textShadow: "1px 1px #000" }}
          >
            <h1>
              Willkommen bei
              <br />
              Intonica
            </h1>
          </header>
        </div>
      </div>

      <div
        className="container-fluid text-center align-items-center notch"
        style={{
          backgroundColor: "var(--brightColor)",
          minHeight: "80vh",
          padding: "10vh",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            {/* <h1>Passwort zurücksetzen</h1> */}
            <p>
              Gib bitte die in deinem Intonica-Profil hinterlegte E-Mail-Adresse
              ein und klicke auf "Passwort anfordern". Wir senden dir per Mail
              einen persönlichen Zugang zu deinem Profil, damit du dein Passwort
              erneuern kannst.
            </p>
            <div>
              <form>
                {emailHasBeenSent && <div>An email has been sent to you!</div>}
                {error !== null && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="userEmail"
                    id="userEmail"
                    value={email}
                    onChange={(event) => onChangeHandler(event)}
                    placeholder="E-Mail-Adresse"
                  />
                </div>
                <button
                  type="submit"
                  onClick={(event) => {
                    sendResetEmail(event);
                  }}
                  className="btn btn-primary"
                >
                  Passwort zurücksetzen
                </button>
              </form>
              <Link to="/signin">&larr; zurück zum Login</Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PasswordReset;
