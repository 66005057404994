import NavBar from "../NavBars/NavBarInApp2";
import Footer from "../Footer/Footer";
import ComponentCard from "./ComponentCard";
import { useState, useEffect } from "react";
import "./Dashboard.css";

const Dashboard = ({ dataUserContext, navigate }) => {
  const content = [
    {
      cardTitle: "Akkorde",
      cardText:
        "Akkordtöne sauber intonieren. Tonleitern und Arpeggios auf Intonation üben. Hier findest Du Dein musikalisches Fitnesstraining für die tägliche Routine.",
      cardLink: "/chords",
      cardImgUrl: "/assets/menu/chords_soon.jpg",
    },
    {
      cardTitle: "Ensemblespiel",
      cardText:
        "Spiele mit einem professionellen Quartett im vierstimmigen Satz und erfahre, wie gut Du mit anderen Musikern verschmelzen kannst.",
      cardLink: "/ensemble",
      cardImgUrl: "/assets/menu/ensemble.jpg",
    },
    {
      cardTitle: "Übungen",
      cardText:
        "Übungen und tägliche Einspiel-Routinen für Duos, Trios oder Gruppen.",
      cardLink: "",
      cardImgUrl: "/assets/menu/exercise_soon.jpg",
    },
  ];
  const listCards = content.map((item) => {
    return (
      <ComponentCard
        key={item.cardTitle}
        cardTitle={item.cardTitle}
        cardText={item.cardText}
        cardLink={item.cardLink}
        cardImgUrl={item.cardImgUrl}
      />
    );
  });

  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (dataUserContext.stripe.role !== "premium") {
      console.log(dataUserContext.stripe.role);
      setShowAlert(true);
    }
  }, [dataUserContext.stripe]);

  return (
    <div className="h-100">
      <NavBar
        dataUserContext={dataUserContext}
        navigate={navigate}
        small={false}
        showUserProfileModal={showUserProfileModal}
        setShowUserProfileModal={setShowUserProfileModal}
      />
      <div className="container-fluid text-center align-items-center dashboardContent">
        <div className="d-flex justify-content-evenly">
          <div
            className="d-flex justify-content-evenly"
            style={{ maxWidth: "1000px", minWidth: "1000px" }}
          >
            {listCards}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
