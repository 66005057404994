import Navbar from "../NavBars/NavBarInApp2";
import Footer from "../Footer/Footer";

const MobileScreen = ({
  modul,
  navigate,
  photoURL,
  setGoToPlayer,
  transparent,
}) => {
  return (
    <div
      className="h-100"
      style={{
        backgroundImage: "url(/assets/background_nav.jpg)",
        // backgroundSize: "cover",
      }}
    >
      <Navbar
        modul={modul}
        navigate={navigate}
        photoURL={photoURL}
        setGoToPlayer={setGoToPlayer}
      />
      <div
        className="container-fluid notch"
        style={{
          backgroundColor: "var(--brightColor)",
          minHeight: "85vh",
          paddingTop: "10vh",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        <h2>Schön, dass du bei uns bist!</h2>
        <p>
          <small>
            Leider haben wir festgestellt, dass Dein Bildschirm zu klein ist.{" "}
            <b>
              Wechsel doch bitte auf ein größeres Display oder halte Dein Tablet
              horizontal, im Landscape-Modus.
            </b>
            <br /> Wir arbeiten an einer Version für kleine Bildschirme.
            <br />
            <br />
            Viele Grüße
            <br />
            Dein Intonica-Team
          </small>
        </p>
        <Footer transparent={transparent} />
      </div>
    </div>
  );
};

export default MobileScreen;
