import Footer from "../Footer/Footer";
import NavBar from "../NavBars/NavBar";

const NoUser = () => {
  return (
    <div className="h-100">
      <NavBar modul={"Ensemble"} small={true} />
      <div
        className="container-fluid"
        style={{
          backgroundColor: "var(--brightColor)",
          height: "90vh",
          minHeight: "90vh",
          maxHeight: "90vh",
          padding: "5vh",
        }}
      >
        <div className="conatiner-fluid align-items-center text-center ">
          <div className="lds-heart">
            <div></div>
          </div>
          <p>
            <b>Einen Moment, wir leiten Dich weiter...</b>
          </p>
          <br />
          <br />
          <p>
            <b>
              Dauert es länger als üblich? Dann melde Dich bitte erneut an:{" "}
            </b>
          </p>
          <a className="btn btn-lg btn-primary" href="/signin">
            zur Anmeldung
          </a>
        </div>
      </div>
      <Footer transparent={true} />
    </div>
  );
};

export default NoUser;
