import Footer from "../Components/Footer/Footer";

const ErrorPage = () => {
  return (
    <div className="h-100" style={{ marginTop: "15vh" }}>
      <header className="text-center">
        <a href="/" target="_blank" rel="noopener">
          <img
            style={{ width: "300px" }}
            alt="intonica"
            src="/assets/logo/logo_big_text_black.svg"
          />
        </a>
      </header>
      <div className="row">
        <div className="small-12 column inner text-center">
          <h2>Es ist ein Problem aufgetreten.</h2>
          <p>
            Wir arbeiten an einer Lösung.
            <br />
            Versuche es bitte später noch einmal.
          </p>
          <p>
            <a
              href="/"
              className="btn btn-lg shadow-none"
              style={{
                backgroundColor: "#5b6188",
                borderColor: "#5b6188",
                color: "#fff",
              }}
            >
              Zurück zur Startseite
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
