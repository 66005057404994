import React from "react";
import Footer from "../Footer/Footer";

const LandingPage = () => {
  return (
    <div
      className="landing"
      style={{
        backgroundImage: "url(/assets/background.jpg)",
        backgroundSize: "cover",
      }}
    >
      <nav className="navbar container-xl flex-wrap flex-md-nowrap fixed-top text-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/" aria-label="Bootstrap">
            <img src="assets/logo/logo_big_text.svg" alt="" height="100px" />
          </a>
        </div>
      </nav>

      <header
        className="text-white text-center align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <div className="header-content">
                <h1 style={{ textShadow: "1px 1px #000" }}>
                SIMPLY IN TUNE!
                </h1>
                <h3 style={{ textShadow: "1px 1px #000" }}>
                  Der digitale Sparringspartner für Deine Intonation.
                  <br />
                  Auditiv, Praxisnah und alles andere als trocken.
                </h3>
                <a className="btn btn-lg btn-primary" href="/signin">
                  Start
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />
    </div>
  );
};

export default LandingPage;
