import React, { useState, useRef, useEffect, Fragment } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { auth } from "../../services/Firebase";
import CustomModal from "../Moduls/Ensemble/CustomModal";
import { signOut } from "firebase/auth";

const NavBarInApp = ({
  dataUserContext,
  module,
  navigate,
  small,
  showUserProfileModal,
  setShowUserProfileModal,
  setShowOffcanvas,
}) => {
  const componentIsMounted = useRef(false);

  const handleCloseProfileModal = () => setShowUserProfileModal(false);
  const handleShowProfileModal = () => setShowUserProfileModal(true);
  const handleCloseHelpModal = () => setShowHelpModal(false);
  const handleShowHelpModal = () => setShowHelpModal(true);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  //useEffect
  useEffect(() => {
    if (module === "Ensemble" || module === "Ensemble-Player") {
      setShowHelp(true);
    } else {
      setShowHelp(false);
    }
    if (module === "Ensemble-Player") {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [module]);
  //custom Hook -> await profil (user)...
  useEffect(() => {
    if (dataUserContext.user) {
      componentIsMounted.current = true;
      console.log("Component Mounted Navbar");
      return () => {
        componentIsMounted.current = false;
        console.log("Component Dissmounted Navbar");
      };
    }
  }, [dataUserContext.user]);

  return (
    <Fragment>
      <nav
        className="nav align-items-center justify-content-between dashboardTop"
        style={
          small
            ? {
                minHeight: "10vh",
                maxHeight: "10vh",
              }
            : {}
        }
      >
        {small ? null : null}
        <li className="nav-item" style={{ marginLeft: "5vw" }}>
          <div className="d-flex align-items-center">
            <a href="/profile">
              <img src="assets/logo/logo_intonica.svg" height="60px" alt="" />
            </a>
          </div>
        </li>

        <li className="nav-item">
          <h1 style={{ textShadow: "1px 1px #000" }}>{module}</h1>
        </li>

        <li className="nav-item">
          <div
            className="d-flex justify-content-end align-items-center"
            style={{ minWidth: "153.66px", marginRight: "5vw" }}
          >
            {showSettings ? (
              <div className="nav-link p-1">
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    setShowOffcanvas(true);
                  }}
                >
                  <i className="fas fa-cog p-1"></i>
                </Button>
              </div>
            ) : null}
            {showHelp ? (
              <div className="nav-link p-1">
                <Button variant="outline-dark" onClick={handleShowHelpModal}>
                  Hilfe
                </Button>
              </div>
            ) : null}
            <div className="nav-link p-1">
              <Button
                className="dashboardTopNavButton faqButton"
                variant="outline-dark"
                onClick={() => {
                  window.open("https://www.intonica.de/faq", "_blank");
                }}
                value="Go to FAQ"
              >
                FAQ
              </Button>
            </div>
            <div className="nav-link p-1">
              <Button
                className="dashboardTopNavButton"
                variant="outline-dark"
                onClick={handleShowProfileModal}
              >
                <i className="fas fa-user p-1"></i>
              </Button>
            </div>
          </div>
        </li>
      </nav>
      <CustomModal onClose={handleCloseHelpModal} show={showHelpModal}>
        <div
          className="container-fluid d-flex justify-content-center"
          style={{
            height: "90vh",
            minHeight: "90vh",
            maxHeight: "90vh",
            paddingTop: "2vh",
          }}
          onClick={() => {
            handleCloseHelpModal();
          }}
        >
          <LazyLoadImage
            alt={"uebersicht player"}
            src={"assets/ensemble/Info-Bild_small.png"} // use normal <img> attributes as props
            width={"950"}
            height={"475"}
          />
        </div>
      </CustomModal>

      <Modal
        show={showUserProfileModal}
        onHide={handleCloseProfileModal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Einstellungen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>
              Hallo <b>{dataUserContext.user.displayName}</b>, deine aktuelle
              Rolle ist: <b>{dataUserContext.stripe.role}.</b>
            </h5>
            <br />
            <p>
              <small>
                <b>Schön, dass Du zu uns gefunden hast und die App testest!</b>{" "}
                Wir können Intonica weiterhin kostenlos für alle anbieten, die
                uns bereits in der frühen Phase begleiten. Wir möchten gerne von
                Deinem Feedback lernen und die App weiter verbessern. Zusätzlich
                wollen wir bis dahin noch das Akkord- und das Übungsmodul
                fertigstellen.
                <br /> Es kann auch sein, dass noch ein paar kleine Fehler
                auffallen, bitte verzeih uns diese und melde diese doch gerne an{" "}
                <a href="mailto:info@intonica.de?subject=Feedback - App">
                  info@intonica.de
                </a>{" "}
                <br />
                <b>
                  Wenn Du das Projekt und uns zusätzlich zu Deinem Feedback
                  unterstützen willst, wäre es eine große Hilfe schon jetzt ein
                  Premium Abo abzuschließen.
                </b>
                <br />
                <br />
                Wir freuen uns sehr über Dich!
                <br /> Dein Intonica Team
              </small>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p>Hier kannst Du Dich abmelden: </p>
          <Button
            variant="secondary"
            onClick={() => {
              signOut(auth).then(() => navigate("/"));
            }}
          >
            Abmelden
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default NavBarInApp;
