import React from "react";
import { BrowserRouter } from "react-router-dom";

import "./App.css";

import Application from "./Components/Tree";
import UserProvider from "./providers/UserProvider";
import ErrorBoundary from "./services/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <UserProvider>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </UserProvider>
    </ErrorBoundary>
  );
}
export default App;
