import React from "react";
import { useNavigate } from "react-router-dom";

import { auth, resendEmail } from "../../services/Firebase";
import { signOut } from "firebase/auth";

import Footer from "../Footer/Footer";
import "./notch.css";

const ConfirmMail = ({ email }) => {
  const navigate = useNavigate();
  return (
    <div
      className="landing h-100"
      style={{
        backgroundImage: "url(/assets/background_nav.jpg)",
        // backgroundSize: "cover",
      }}
    >
      <nav className="navbar container-xl flex-wrap flex-md-nowrap fixed-top text-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/" aria-label="Bootstrap">
            <img src="assets/logo/logo_big_text.svg" alt="" height="100px" />
          </a>
        </div>
      </nav>
      <div className="container-fluid" style={{ minHeight: "20vh" }}>
        <div
          className="row align-items-center text-center"
          style={{ minHeight: "20vh" }}
        >
          <header
            className="text-white "
            style={{ textShadow: "1px 1px #000" }}
          >
            <h1>
              Willkommen bei
              <br />
              Intonica
            </h1>
          </header>
        </div>
      </div>

      <div
        className="container-fluid text-center align-items-center notch"
        style={{
          backgroundColor: "var(--brightColor)",
          minHeight: "80vh",
          padding: "10vh",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <h2>Noch ein Schritt</h2>
            <p>
              Wir haben Dir eine E-Mail zur Bestätigung geschickt. Überprüfe
              bitte Deinen Post-eingang und folge den Anweisungen.
            </p>
            <h1>@</h1>
            <h3>{email}</h3>
            <br />
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => window.location.reload()} //HIER IST EIN UNENDLICHKEITSFEHLER!!! ()=> WICHTIG um nur einmal auszuführen!
              >
                Ich habe meine Mail verifiziert.
              </button>
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => resendEmail()}
              >
                Sende mir erneut eine Mail.
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  signOut(auth).then(() => {
                    navigate("/signup");
                  });
                }}
              >
                Falsche E-Mail?
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ConfirmMail;
