const NavBar = ({ modul, small }) => {
  return (
    <nav
      className="nav align-items-center justify-content-between"
      style={
        small
          ? {
              minHeight: "10vh",
              maxHeight: "10vh",
              backgroundImage: "url(/assets/background_nav.jpg)",
            }
          : {
              minHeight: "15vh",
              maxHeight: "15vh",
              backgroundImage: "url(/assets/background_nav.jpg)",
            }
      }
    >
      {small ? null : null}
      <li className="nav-item" style={{ marginLeft: "5vw" }}>
        <div className="d-flex align-items-center">
          <a href="/signin">
            <img
              src="assets/logo/logo_big_text.svg"
              height="60px"
              alt=""
            />
          </a>
        </div>
      </li>

      <li className="nav-item" style={{ color: "white" }}>
        <h1 style={{ textShadow: "1px 1px #000" }}>{modul}</h1>
      </li>

      <li className="nav-item">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ minWidth: "153.66px", marginRight: "5vw" }}
        >
          <a
            className="nav-link p-2"
            href="https://www.intonica.de/faq"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            FAQ
          </a>
          {/* <div>
            <i className="fas fa-user p-2" style={{ color: "white" }}></i>
          </div> */}
        </div>
      </li>
    </nav>
  );
};

export default NavBar;
