import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import firebase, { auth, functions } from "../../services/Firebase";

import Footer from "../Footer/Footer";

import { UserContext } from "../../providers/UserProvider";
import "./notch.css";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dataUserContext = useContext(UserContext);

  useEffect(() => {
    // console.log(dataUserContext);
    if (dataUserContext.user) navigate("/profile");
  }, [dataUserContext.user]);

  const loginGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
    console.log("go to google");
  };

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        setError("Error signing in with password and email!");
        console.error("Error signing in with password and email", error.code);
        if (error.code === "auth/user-not-found") {
          setError(
            <a href="/signup">
              Ups.. die E-Mail kennen wir noch nicht. Möchtest Du dich
              registrieren?
            </a>
          );
        }
        setPassword("");
      });
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div
      className="landing h-100"
      style={{
        backgroundImage: "url(/assets/background_nav.jpg)",
        // backgroundSize: "cover",
      }}
    >
      <nav className="navbar container-xl flex-wrap flex-md-nowrap fixed-top text-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/" aria-label="Bootstrap">
            <img src="assets/logo/logo_big_text.svg" height="100px" alt="" />
          </a>
        </div>
      </nav>
      <div className="container-fluid" style={{ minHeight: "20vh" }}>
        <div
          className="row align-items-center text-center"
          style={{ minHeight: "20vh" }}
        >
          <header className="text-white ">
            <h1 style={{ textShadow: "1px 1px #000" }}>
              Willkommen bei
              <br />
              Intonica
            </h1>
            {/* <div className="lds-heart">
              <div></div>
            </div> */}
          </header>
        </div>
      </div>

      <div
        className="container-fluid text-center align-items-center notch"
        style={{
          backgroundColor: "var(--brightColor)",
          minHeight: "80vh",
          padding: "8vh",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <h1>Anmeldung</h1>
            <h6>
              Sie haben kein Konto?{" "}
              <a href="/signup">
                {/* <i className="fas fa-arrow-left"></i> Zurück zum Login */}
                Registrieren
              </a>
            </h6>
            {error !== null && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <form>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="userEmail"
                  id="userEmail"
                  value={email}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="E-Mail-Adresse"
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="userPassword"
                  id="userPassword"
                  value={password}
                  onChange={(event) => onChangeHandler(event)}
                  placeholder="Passwort"
                />
                <div id="passwordHelp" className="form-text">
                  <a href="/reset">Passwort vergessen?</a>
                </div>
              </div>
              <button
                type="submit"
                onClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }}
                className="btn btn-primary"
              >
                Anmelden
              </button>
            </form>
            {/* <a href="/signup">
              Account erstellen <i className="fas fa-arrow-right"></i>
            </a> */}

            {/* <p>Oder anmelden mit:</p>
            <div className="g-signin2" data-onsuccess="onSignIn"></div>
            <img
              src="assets/btn_google_signin.png"
              alt=""
              onClick={() => {
                loginGoogle();
              }}
            /> */}
          </div>
          <div>
            <hr />
          </div>
          <p>
            <small>
              * Mit Ihrer Anmeldung stimmen Sie unseren{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.intonica.de/agb"
              >
                Nutzungsbedingungen
              </a>{" "}
              sowie dem Erhalt von E-Mails und Updates von Intonica zu und
              bestätigen, dass Sie unsere{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.intonica.de/datenschutz"
              >
                Datenschutzrichtlinie
              </a>{" "}
              zur Kenntnis genommen haben.
            </small>
          </p>
          <p>
            <small>
              Sie nehmen außerdem zur Kenntnis, dass Intonica für eine
              bestmögliche Nutzererfahrung Cookies verwendet. Diese Website wird
              durch reCAPTCHA geschützt und es gelten die{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy"
              >
                Datenschutzerklärung
              </a>{" "}
              und{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/terms"
              >
                Nutzungsbedingungen
              </a>{" "}
              von Google.
            </small>
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SignIn;
